<!--
 * @Descripttion: 专家管理
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-05 17:38:52
-->
<template>
	<div class="home">
		<div class="flex-col-between">
			<van-nav-bar title="专家管理" left-arrow right-text="添加专家" @click-left="$router.push('/mine')" @click-right="$router.push('/expertAdd')" />
			<van-search v-model="searchaV" shape="round" show-action placeholder="请输入专家姓名">
				<template #action>
					<div @click="onSearch" class="fc fw">搜索</div>
				</template>
			</van-search>

			<div class="flex-around" style="background-color:white" v-if="hasData">
				<h3 :class="{f5:true,title : true,'active-title': activeTitle ==item.id}" @click="changeParams(item)" v-for="(item,index) in majorsTree"
				 :key="index">
					{{item.text}}
				</h3>
			</div>
		</div>

		<div class="flex-normal" v-if="hasData">
			<div class="inline div3">
				<ul class="leftUl" ref="typeList">
					<li v-for="(item,index) in secondMajors" :class="{active : activeTypeId == item.id}">
						<div class="ttt" :class="{lightg: item.expand}">
							<p @click="changeParams(item)" :class="{fc: item.id == selectedSecondMajor.id}">{{ item.text }}</p>
							<i :class="item.expand ? 'icon-up':'icon-down'" @click="item.expand = !item.expand" />
						</div>
						<ul v-show="item.expand" :class="{lightg: item.expand}">
							<li v-for="(node,i) in item.nodes" :key="'node'+i" class="third" :class="{fc: node.id == selectedThirdMajor.id}"
							 @click="changeParams(node)">{{ node.text }}</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="inline div7">
				<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="pageSelect" class="rightUl" ref="rightUl">
					<div v-for="(item,index) in expertList" :key="index" @click="goExpertEdit(item)">
						<i class="status-ban" v-if="item.enableStatus =='1'"></i>
						<div class="pad02">
							<i :class="{'icon-male':item.sex=='1','icon-female': item.sex=='2','icon-zhongxing': (item.sex==''||item.sex==null)}"></i>
						</div>
						<div>
							<h3 class="f5">{{ item.name }}</h3>
							<p class="gray">手机号：{{item.mobilePhone}}</p>
							<p class="van-ellipsis">工作单位：{{item.orgName}}</p>
						</div>
					</div>
				</van-list>
			</div>
		</div>
		<!-- 无数据时 显示的提示 -->
		<div v-else class="pad">
			<div class="whiteBg b1 flex-col pad-b">
				<img src="/static/img/null/expert.png" class="div7">
				<h3 class="f45">无专家信息</h3>
				<p class="gray pad-b mar-t">请点击添加专家，添加专家信息。</p>
			</div>
			<div class="pageBtn whiteBg center pad-b">
				<button class="btn" @click="$router.push('/expertAdd')">添加专家</button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Search,
		List
	} from 'vant';
	export default {
		name: "Home",
		data() {
			return {
				searchaV: '',
				activeTitle: 0,
				activeTypeId: '',

				typeList: [],
				expertList: [],
				loading: true,
				finished: false,
				pageSize: 10,
				pageIndex: 1,
				// 是否有专家数据 默认为  有
				hasData: true,
				activeIndex: '0',
				firstClasses: [],
				majorExperts: [],
				org: {
					id: '',
				},
				majorsTree: [],
				secondMajors: [],
				selectedSecondMajor: {},
				selectedThirdMajor: {},
				majorParams: {
					lev: '',
					majorId: ''
				},
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},
		components: {
			[List.name]: List,
			[Search.name]: Search,
		},


		beforeMount() {
			//清空企业下专家开通专业数据
			this.$ls.remove("orgExpertMajors");
			//清空新增专家临时数据
			this.$ls.remove("addExpertData");
			//清空编辑专家的数据
			this.$ls.remove('expertEdit_data');
		},
		created: function() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			this.getAllExpert()
			this.initMajor()
		},

		methods: {
			/**
			 * @Description: 查询该机构下的所有专家，用户判断是否有数据
			 * @Author: Niklaus
			 * @Date: 2021-05-28 08:21:48
			 */
			getAllExpert() {
				this.$ajax({
					url: "/gateway/ed/expert/tbEdExpertInfo/queryAllExpertWithOrgId",
					method: "post",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: {
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid")),
						orgId: this.$ls.get("edCurrentOrg").id,
					},
				}).then(res => {
					if (res.data.rows.length > 0)
						this.hasData = true
					else
						this.hasData = false
				}).catch(error => console.log(error))
			},

			/**
			 * @Description: 查询专业树（三级）
			 * @Author: Niklaus
			 * @Date: 2021-05-27 14:41:50
			 */
			initMajor() {
				let params = new URLSearchParams()
				params.append("orgId", this.$ls.get("edCurrentOrg").id)
				params.append("openid", this.workweixin?this.openUserid:this.$ls.get("openid"))
				params.append("countFlag", "0")
				this.$ajax({
					url: '/gateway/ed/pc/pcExpertsController/orgMajorsTreeCountFlag',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params,
				}).then(({
					data
				}) => {
					if (data.success)
						this.majorsTree = data.result || []
					if (this.majorsTree.length > 0) {
						this.majorParams.lev = 1
						this.majorParams.majorId = this.majorsTree[0].id
						this.pageSelect()
						this.activeTitle = this.majorsTree[0].id
						this.secondMajors = this.majorsTree[0].nodes.filter(item => {
							this.$set(item, 'expand', false)
							this.$set(item, 'selected', false)
							return item
						})
					} else {
						this.hasData = false
					}
				}).catch(error => {
					this.$toast.fail('专业数据查询错误');
					console.log(error)
				})
			},

			/**
			 * @Description: 执行搜索
			 * @Author: Niklaus
			 * @Date: 2021-05-28 08:53:46
			 */
			onSearch() {
				this.expertList = []
				this.loading = true
				this.finished = false
				this.pageIndex = 1
				this.pageSelect()
			},

			/**
			 * @Description: 分页查询专家数据
			 * @Author: Niklaus
			 * @Date: 2021-05-28 08:18:49
			 */
			pageSelect() {
				this.$toast({
					type: 'loading',
					message: '加载中，请稍候....',
					forbidClick: true,
					className: 'w4',
					duration: 10000
				})

				var params = {}
				params.pageNo = this.pageIndex;
				params.pageSize = this.pageSize;
				params.openid = this.workweixin?this.openUserid:this.$ls.get("openid");
				params.orgId = this.$ls.get("edCurrentOrg").id
				params.lev = this.majorParams.lev
				params.majorId = this.majorParams.majorId
				params.name = this.searchaV
				//查询
				this.$ajax({
					url: "/gateway/ed/pc/pcExpertsController/list",
					method: "get",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						this.loading = false
						this.$toast.clear()
						this.expertList = this.expertList.concat(res.data.result.records);
						if (this.expertList.length >= res.data.result.total)
							this.finished = true
						else
							this.pageIndex++
					} else {
						this.$toast.fail('查询专家数据失败！')
					}
				}).catch(error => {
					this.$toast.fail('查询专家错误')
					console.log(error)
				})
			},

			/**
			 * @Description: 处理不同级别的专业查询参数
			 * @Author: Niklaus
			 * @Date: 2021-05-28 08:19:25
			 * @param {*} item 专业节点
			 */
			changeParams(item) {
				if (item.lev == '1') {
					this.activeTitle = item.id
					this.selectedSecondMajor = {}
					this.selectedThirdMajor = {}
					this.secondMajors = item.nodes
					this.secondMajors = item.nodes.filter(item => {
						this.$set(item, 'expand', false)
						this.$set(item, 'selected', false)
						return item
					})
				} else if (item.lev == '2') {
					this.activeTitle = ''
					this.selectedSecondMajor = item
					this.selectedThirdMajor = {}
				} else if (item.lev == '3') {
					this.activeTitle = ''
					this.selectedSecondMajor = {}
					this.selectedThirdMajor = item
				}
				this.majorParams.lev = item.lev
				this.majorParams.majorId = item.id

				this.expertList = []
				this.loading = true
				this.finished = false
				this.pageIndex = 1
				this.pageSelect()
			},
			//跳转专家管理页面
			goExpertEdit(item) {
				this.$ls.set('expertEdit_data', item);
				this.$router.push('/expertEdit');
			},
		}
	};
</script>

<style scoped>
	.div3 {
		width: 3.33rem;
	}

	.div7 {
		width: calc(100% - 3.33rem);
	}

	.home {
		background-color: #F5F5F5;
		min-height: 100vh;
	}

	.leftUl {
		background-color: #fff;
	}

	.leftUl,
	.rightUl {
		height: calc(100vh - 3.95rem);
		overflow: auto;
	}

	.leftUl>li {
		font-weight: bold;
		background-color: #ffffff;
		box-sizing: border-box;
	}

	li.active {
		background-color: #E1FFFB;
		color: #00CCB2;
	}

	.rightUl {
		padding: 0.2rem;
	}

	.rightUl>div {
		background-color: #ffffff;
		border-radius: 0.2rem;
		display: flex;
		display: -webkit-flex;
		padding: 0.2rem 0.2rem 0.3rem 0rem;
		margin-top: 0.15rem
	}

	.rightUl>div>div>i {
		width: 1.5rem;
		height: 1.5rem;
	}

	.rightUl>div>div:last-child {
		width: calc(100% - 1.9rem);
	}

	.rightUl>div>div>h3 {
		font-size: 0.45rem;
		width: calc(100% - 0.5rem);
	}

	.pad {
		padding: .32rem .43rem;
	}

	.flex-col-between {
		align-items: unset;
		width: 100%
	}

	.ttt {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: .21rem .21rem .21rem 0;
	}

	.ttt>p {
		width: calc(100% - 0.5rem);
		font-size: .48rem;
		line-height: .98rem;
		padding-left: .31rem;
	}

	.ttt>i {
		width: .43rem;
		height: .43rem;
	}

	.third {
		padding-left: .56rem;
	}

	.third.fc {
		color: #00CCB2;
	}
</style>
